import React from 'react'

import { Layout } from 'components/global'
import { Hero, Social, OurFirstShow } from 'components/blocks'
import styled from 'styled-components'

const OurFirstShowPage = () => (
  <Layout pageName="our-first-show-page">
    <Hero />
    <div className="w-[90%] mx-auto max-w-[1400px]">
      <h1 className="text-center text-purple uppercase text-[25px] pt-12 leading-7 md:text-[30px] xl:text-[42px] pb-6">
        Our First Show Application Form
      </h1>
      <section className="lg:grid lg:grid-cols-2 lg:py-6 lg:gap-6">
        <div>
          <p className="text-purple">
            Welcome to the Our First Show Scheme for Disney’s ALADDIN The
            Musical that enables school groups to purchase a limited number of
            £8 tickets to the touring musical on select performances. To take
            part in the scheme, fill in and return the application form before
            Tuesday 19th September.
          </p>
          <p className="text-purple">
            <strong>Do this by filling in the online form below.</strong>
          </p>
          <p className="text-purple">
            The Our First Show Scheme supports cultural engagement within
            schools by giving pupils who may not otherwise be able the chance to
            experience a live theatre production.
          </p>
        </div>
        <div>
          <p className="text-purple">
            <strong>Key information you need to know</strong>
          </p>
          <ul className="list-disc pl-[2rem]">
            <li className="text-purple">Tickets are £8 each</li>
            <li className="text-purple">
              A maximum of 100 tickets can be allocated per school/youth group,
              this includes teachers/leaders
            </li>
            <li className="text-purple">
              The scheme is open to primary/secondary schools and youth groups
            </li>
            <li className="text-purple">You can choose from the dates below</li>
            <li className="text-purple">
              The closing date for applications is Tuesday 19th September
            </li>
            <li className="text-purple">
              You will be contacted if you have been successful with your
              application by Wednesday 20th September
            </li>
            <li className="text-purple">
              Successful applicants must pay by Friday 13th October
            </li>
          </ul>
        </div>
      </section>
    </div>
    <OurFirstShow />
    <Social />
  </Layout>
)

export default OurFirstShowPage
